import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const moveToTwitter = () => {
    window.open("https://twitter.com/metaseoul_nbt");
  };

  const moveToDiscord = () => {
    window.open("https://discord.gg/YBSrr2U7bt");
  };

  const moveToEmail = () => {
    window.open("mailto:metaseoul@nbt.com");
  };

  const gotoLink = (url) => {
    window.open(url);
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    window.addEventListener("resize", checkIsMobile);

    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  return (
    <div
      className="App"
      style={{
        width: "100%",
        height: "100vh",
        position: "relative",
        background: "#04061C",
      }}
    >
      <img
        src="/images/bg.png"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "white",
          width: "100%",
          height: "100vh",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "18px 0",
            background: "#04061C",
            backdropFilter: "blur(10px)",
            position: "fixed",
          }}
        >
          <img
            src="/images/logo.svg"
            style={{
              width: 136,
              height: 24,
              margin: "auto",
            }}
          />
        </div>
        <div
          style={{
            fontSize: 12,
            fontWeight: 300,
            lineHeight: "160%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flex: 1,
            paddingTop: 64,
          }}
        >
          <div
            style={{
              fontSize: 30,
              fontWeight: 700,
              lineHeight: "160%",
              textAlign: "center",
            }}
          >
            Meta Seoul
          </div>
          <div
            style={{
              fontSize: 30,
              fontWeight: 700,
              lineHeight: "160%",
              textAlign: "center",
            }}
          >
            서비스 종료 안내
          </div>
          <div
            style={{
              marginTop: 24,
            }}
          >
            안녕하세요, Meta Seoul입니다.
          </div>
          <div className="fd-r center">
            <span
              style={{
                fontWeight: 700,
              }}
            >
              2024년 8월 31일
            </span>
            자로 서비스를 종료합니다.
          </div>
          <br />
          <div>그동안 Meta Seoul 서비스를 사랑해주신 이용자분들께</div>
          <div>깊은 감사의 말씀을 전하며, 마지막 인사를 드립니다.</div>
          <div>저희는 더 나은 서비스 플랫폼 개발과 이용 환경 개선을 위해</div>
          <div>최선을 다해왔으나, 메타버스와 Web3.0 등 관련 산업의 급격한</div>
          <div>
            변화에 따라 서비스 종료라는 어려운 결정을 내리게 되었습니다.
          </div>
          <br />
          <div>서비스 종료와 관련된 일정 및 보유하고 계신 타일과 마일리지</div>
          <div>환급 절차에 대해서는 이미 사전 안내를 드렸습니다.</div>
          <div>추가적으로 궁금한 사항이나 문의가 있으시면, 아래 고객센터로</div>
          <div>연락해 주시기 바랍니다.</div>
          <br />
          <div
            className="fd-r center"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            고객센터 문의하기&nbsp;:&nbsp;
            <a
              href="mailto:metaseoul@nbt.com"
              style={{
                color: "blue",
              }}
            >
              <u>metaseoul@nbt.com</u>
            </a>
          </div>
          <br />
          <div>감사합니다.</div>
          <div>Meta Seoul 드림</div>
        </div>
        <div
          className="footer"
          style={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            alignItems: "flex-start",
            padding: `${isMobile ? "16px 20px" : "40px 80px"}`,
            fontSize: 14,
            fontWeight: 300,
            background: "#04061C",
          }}
        >
          <footer
            className="info"
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "auto",
            }}
          >
            <div
              className="company"
              style={{
                display: "flex",
                flexDirection: `${isMobile ? "column" : "row"}`,
                alignItems: `${isMobile ? "flex-start" : "center"}`,
                flexWrap: "wrap",
              }}
            >
              <button
                style={{
                  color: "white",
                  fontWeight: 300,
                  textDecoration: "none",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                }}
                onClick={() => gotoLink("https://www.nbt.com")}
              >
                (주)엔비티
              </button>
              {!isMobile && (
                <div
                  style={{
                    width: 1,
                    height: 14,
                    background: "#262628",
                    margin: "0 16px",
                  }}
                />
              )}
              <p style={{ margin: 0, lineHeight: "160%" }}>대표자 박수근</p>
              {!isMobile && (
                <div
                  style={{
                    width: 1,
                    height: 14,
                    background: "#262628",
                    margin: "0 16px",
                  }}
                />
              )}
              <p style={{ margin: 0, lineHeight: "160%" }}>
                통신판매업신고번호 2016-서울서초-2176
              </p>
              {!isMobile && (
                <div
                  style={{
                    width: 1,
                    height: 14,
                    background: "#262628",
                    margin: "0 16px",
                  }}
                />
              )}
              <p
                style={{
                  textAlign: "left",
                  margin: 0,
                  lineHeight: "160%",
                }}
              >
                서울특별시 서초구 서초대로38길 12, 마제스타시티 타워1 14층,
                06655
              </p>
              {!isMobile && (
                <div
                  style={{
                    width: 1,
                    height: 14,
                    background: "#262628",
                    margin: "0 16px",
                  }}
                />
              )}
              <p style={{ margin: 0, lineHeight: "160%" }}>
                사업자등록번호 211-88-83652
              </p>
              {!isMobile && (
                <div
                  style={{
                    width: 1,
                    height: 14,
                    background: "#262628",
                    margin: "0 16px",
                  }}
                />
              )}
              <button
                onClick={moveToEmail}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: 14,
                  fontWeight: 300,
                  color: "white",
                  textDecoration: "underline",
                  padding: 0,
                }}
              >
                metaseoul@nbt.com
              </button>
            </div>
            <div
              className="personal"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: 8,
              }}
            >
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: 16,
                  color: "white",
                  fontWeight: 500,
                  padding: 0,
                }}
                onClick={() => gotoLink("https://docs.metaseoul.kr/web_terms")}
              >
                서비스 이용약관
              </button>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: 16,
                  color: "white",
                  fontWeight: 500,
                  padding: 0,
                  marginLeft: 20,
                }}
                onClick={() => gotoLink("https://docs.metaseoul.kr/web_policy")}
              >
                개인정보처리방침
              </button>
            </div>
            <p
              className="copyright"
              style={{
                marginTop: 8,
                fontSize: 14,
                color: "#7b7b7d",
                textAlign: "left",
              }}
            >
              Copyright © NBT Inc. All rights reserved.
            </p>
          </footer>
          <div
            className="link"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <button
              onClick={moveToDiscord}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/images/btn-discord-gray.svg"
                alt="discord"
              />
            </button>
            <button
              onClick={moveToTwitter}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                marginLeft: 20,
              }}
            >
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/images/btn-twitter-gray.svg"
                alt="twitter"
              />
            </button>
            <button
              onClick={moveToEmail}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                marginLeft: 20,
              }}
            >
              <img
                style={{
                  width: 24,
                  height: 24,
                }}
                src="/images/btn-email-gray.svg"
                alt="email"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
